import React ,{useState,useRef}from 'react'
import Navbar from "../../../components/Navbar/Navbar";
import Footer from '../../../components/Footer/Footer';
import {connect} from "react-redux";
import {setDestinationPoint, setSourceAndDestination, setSourcePoint,} from "../../../store/Actions/BookingAction";
import { Helmet } from 'react-helmet';
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { useSearchParams, Link } from "react-router-dom"
import MobileSubScreenLayout from "../../../layout/MobileSubScreenLayout/MobileSubScreenLayout";
import cogoToast from "cogo-toast";
import Loader from "../../../components/Loader/Loader";
import { CustomToast } from "../../../components/CustomToast/CustomToast"
import InnerSearchbar from "../../Home/component/SearchBarRevamp/InnerSearchbar";
import MobileTopBus from "../../Home/component/MobileTopBus";
// icons
import AuthModalLayout from "../../../layout/AuthModalLayout/AuthModalLayout";
import TravelItineraries from './Components/TravelItineraries/TravelItineraries';
import CardGrid from './Components/CradGrid/CardGrid';
import CategoryBlogs from './Components/BlogCategories/BlogCategory';
import FeatureCard from './Components/FeatureCard/FeatureCard';
import "./Blog.scss"
import NueGoScanner from './Components/NueGoScanner/NueGoScanner';


function Blog(props) {

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const navigate = useNavigate();
  return (
    <div className='blog-landing-page'>  
            <Helmet>
                <title>Travel Blogs in India | NueGo</title>
                <link rel="canonical" href="https://www.nuego.in/blog" hreflang="en-IN" />
                <meta name="description" content="NueGo's travel and tourism blogs suggests best tourist attractions and offers advices for better travel experiences. Discover blogs for solo adventure or group getaways." />
                <meta name="keywords" content="travel blogs,travel blog site,tourism blog,travel blog post,short travel blog"></meta>
                <meta name="robots" content="index,follow" />
                <script type='application/ld+json'>
                {JSON.stringify({
                "@context": "http://schema.org",
                "@type": "WebPage",
                "url": "https://www.nuego.in/blog",
                "name": "NueGo Blog",
                "description": "NueGo's travel and tourism blogs suggests best tourist attractions and offers advices for better travel experiences. Discover blogs for solo adventure or group getaways.",
                "publisher": {
                    "@type": "Organization",
                    "name": "NueGo",
                    "logo": {
                    "@type": "ImageObject",
                    "url": "https://cdn.nuego.co.in/greencell/assets/images/Logologo_desktop.svg",
                    "width": 200,
                    "height": 100
                    }
                }
                })}
                </script>
            </Helmet>
            {

                isDesktopOrLaptop ? <AuthModalLayout>
                    <Navbar />
                    <MainContent  isDesktopOrLaptop={isDesktopOrLaptop} props={props} />
                    <Footer/>
                </AuthModalLayout>
                    :
                    <MobileSubScreenLayout back={() => navigate(-1)} title={"NueGo Blog"}>
                        <MainContent isDesktopOrLaptop={isDesktopOrLaptop} props={props} />
                        <Footer/>
                    </MobileSubScreenLayout>
            }
    </div>
  )

}

export const MainContent = ({props}) => {
    //console.log("parameters.get",parameters.get("pnr"))
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const ref = useRef();
    const [selectedSearchFromRecent, setSelectedSearchFromRecent] = useState({});
    const [viewCalendar, setViewCalendar] = useState(false);
    const [
        viewCalendarActivatedBeforeScroll,
        setViewCalendarActivatedBeforeScroll,
    ] = useState(false);

    const {
        setSourceAndDestinationToStore,
        sourcePointListFromStore,
        destinationPointListFromStore,
        userDataFromStore,
        filterDataFromStore,
        filterFromStore
    } = props;

    const openCalendarForMobile = (args) => {
        document.querySelector(".MobileTopBus").style.position = "relative";
        document.querySelector(".MobileTopBus").style.top = "0";

        setViewCalendar(args);
        setViewCalendarActivatedBeforeScroll(true);
    };


    const CategoryHistory = [
        {
            tittle: "10 Famous Tourist Places and Attractions in Agra You Cannot Miss",
            img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/Agra_587%20X%20286%20px.jpg",
            mwebImg: 'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/Agra_mWeb_155%20X%20157%20px.jpg',
            imgAlt: "Taj Mahal: One of the Famous Places to Visit in Agra",
            desc: "Agra! There’s something about that place, a serene and everlasting charm filled with love and romance. Thefamous places in Agra, all exhibit beauty that even pages from history cannot narrate with words.",
            route: "/blog/famous-tourist-places-to-visit-in-agra",
            category: "Heritage"
        },

        {
            tittle: "Your Complete Guide to Places to Visit in Ujjain in 2024",
            img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/Ujjain_587%20X%20286%20px.jpg",
            mwebImg: 'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/Ujjain_mWeb_155%20X%20157%20px.jpg',
            imgAlt: "Best Places To Visit This Monsoon In India",
            desc: "Immense is the attraction of power, and many people spend their lifetimes trying to attain it. Yet, one city has always tested the powerful, and even ripped it off of them, should they be unworthy. We speak, of course, of the ancient cityAvantika, which we all call Ujjain today. One of the four locations of the Kumbh Mela, held every 12 years, the new name Ujjain may have changed how people see the city, but testing the powerful remains an occurrence even to this day.",
            route: "/blog/places-to-visit-in-ujjain",
            category: "Religious"
        },
        {
            tittle: "15 Best Sightseeing Places to Visit in Haridwar",
            img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/Haridwar_587%20X%20286%20px.jpg",
            mwebImg: 'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/Haridwar_mWeb_155%20X%20157%20px.jpg',
            imgAlt: "Har Ki Pauri: One of the most popular attractions in Haridwar.",
            desc: "Haridwar is cool. Both literally and figuratively. But the pleasant weather and hilly bliss aside, Haridwar is a lot cooler for what it has - a plethora of charming pilgrimage sites awaiting its devotees every year. Theplaces to visit in Haridwarare countless, and the temples and ashrams in Haridwar are all a spectacular kaleidoscope of devotion and grace, waiting to be experienced by a passionate tourist such as yourself.",
            route: "/blog/sightseeing-places-to-visit-in-haridwar",
            category: "Religious"
        },
        {
            tittle: "10 Famous Temples in Chennai You Just Cannot Miss",
            mwebImg: 'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/Chennai%20Temples_mWeb_155%20X%20157%20px.jpg', 
            img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/Chennai%20Temples_587%20X%20286%20px.jpg",
            imgAlt: "Best Places To Visit This Monsoon In India",
            desc: "Chennai! The oldest metropolitan city of South India, where one of the oldest languages in the world is spoken. It is no surprise that so many ancient, medieval, and modern temples exist in Chennai, which attract a lot of devotees from around the world throughout the year.",
            route: "/blog/famous-temples-to-visit-in-chennai-you-must-visit",
            category: "Religious"
        },
      ];

    const CategoryMustVisit = [
        {
            tittle: "Don't Skip Out on These Incredible South Indian Tourist Places to Visit",
            img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/Skip%20Out%20on%20These%20Incredible%20South%20Indian%20Tourist%20Places_587%20X%20286%20px.jpg",
            mwebImg:'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/Skip%20Out%20on%20These%20Incredible%20South%20Indian%20Tourist%20Places_mWeb_155%20X%20157%20px.jpg',
            imgAlt: "Best Places to Visit in South India",
            desc: "In this beautiful land known for so many beautiful things, it is no surprise that the beauty of South India is something that cannot be expressed in mere words, let alone using blog posts to describe these places. However, if you want to know which place to visit, what to do in South India, and how to make the best of your trip by visiting theSouth India tourist placesthat will blow your mind, here we are with a list for exactly that.",
            route: "/blog/best-south-indian-tourist-places-to-visit",
            category: "Hidden Gems"
        },
        {
            tittle: "Best Places to Eat in Jaipur—Recommended by Locals! ",
            img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/Best%20Food%20Places%20in%20Jaipur_587%20X%20286%20px.jpg",
            mwebImg:'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/Best%20Food%20Places%20in%20Jaipur_mWeb_155%20X%20157%20px.jpg',
            imgAlt: "Best Places To Visit This Monsoon In India",
            desc: "Food and Jaipur have both been used in the same sentence since times immemorial. In the very place where kings and queens have dined since one could remember, it wouldn’t come as a surprise either. The locals of Jaipur have therefore developed an inner food critic within themselves which has tuned them to demand the finest quality of food wherever they go.",
            route: "/blog/best-food-places-in-jaipur",
            category: "Weekend Trips"
        },
        {
            tittle: "15 Famous Places to Visit in Vijayawada in 2024 for Sightseeing and Tourist Spots",
            mwebImg:'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/15%20Famous%20Places%20to%20Visit%20in%20Vijayawada_mWeb_155%20X%20157%20px.jpg',
            img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/15%20Famous%20Places%20to%20Visit%20in%20Vijayawada_587%20X%20286%20px.jpg",
            imgAlt: "Best Places To Visit This Monsoon In India",
            desc: "Vijayawada, known as the 'City of Victory', celebrates the triumph of Goddess Durga over Mahishasura, offering a cultural tapestry of festivals and temples steeped in mythological significance. Explore its vibrant heritage through majestic temples and annual festivities honoring the divine intervention in ancient legends.",
            route: "/blog/famous-places-to-visit-in-vijayawada",
            category: "Heritage"
        },
        {
            tittle: "20 Best Tourist Attractions and Activities in Noida You Must Try",
            img: "https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/Noida_587%20X%20286%20px.jpg",
            mwebImg:'https://production-nuego-cms.blr1.cdn.digitaloceanspaces.com/cms-web/prod/Blogs/Blog%20Home%20Page/Noida_mWeb_155%20X%20157%20px.jpg',
            imgAlt: "Best Places To Visit This Monsoon In India",
            desc: "Noida. Wow is not just the expression you exclaim here, but also the name of one of the places in Noida. Such is the beauty of advancement and style in Noida that anyone who decides to visit here is bound to be flabbergasted at how uniquely blended and well-planned Noida is after seeing the Noida tourist attractions.",
            route: "/blog/top-tourist-attractions-and-activities-in-noida",
            category: "Heritage"
        },
      ];

    

    

    let componentData;
    if (isDesktopOrLaptop) {
        componentData = {
            title_class: "ubuntu-700w-20s-32h black-1E1E26-color",
            subTitle_class: "open-6000w-20s-28h grey-2-78787d-color",
            outlineCta_class:
                "open-600w-18s-24h teal-2-00A095-color outline-button mb-0 cursor-pointer",
            label_class: "open-700w-16s-24h grey-2-78787d-color",
            input_class: "open-600w-18s-28h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-4 ",
        };
    } else {
        componentData = {
            title_class: "ubuntu-700w-18s-28h black-1E1E26-color",
            subTitle_class: "open-400w-18s-28h grey-2-78787d-color",
            outlineCta_class: "open-600w-18s-24h teal-2-00A095-color cursor-pointer ",
            filledCta_class: "",
            label_class: "open-400w-14s-22h grey-2-78787d-color",
            input_class: "open-600w-16s-24h black-1E1E26-color",
            error_class: "red-F85959-color",
            gstCheckboxText_class: "open-600w-16s-24h black-1E1E26-color",
            inputMarginBottom_class: " mb-3 ",
        };
    }



    return (
        <div className='blog-main-content'>
               {isDesktopOrLaptop && <div className="bread-crumb d-flex">
                        <Link to="/"><p className={'open-600w-14s-22h grey-2-78787d-color mb-0'}>Home</p></Link>
                        <p className={'open-600w-14s-22h grey-2-78787d-color px-1'}>/</p>
                        <p className={'open-600w-14s-22h teal-2-00A095-color mb-0 '}>Blog</p>
                        <script type='application/ld+json'>
                            {JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "BreadcrumbList",
                            "itemListElement": [
                                {
                                "@type": "ListItem",
                                "position": 1,
                                "name": "Home",
                                "item": "https://www.nuego.in"
                                },
                                {
                                "@type": "ListItem",
                                "position": 2,
                                "name": "Blogs",
                                "item": "https://www.nuego.in/blog"
                                }
                            ]
                            })}
                        </script>
                    </div>
                    }
            <FeatureCard/>
            <CategoryBlogs/>
            <TravelItineraries/>
            <CardGrid category={'History'} categoryData={CategoryHistory}/>
            { isDesktopOrLaptop ?
                    <div className="searchbar-div">
                        <div className="semicircle"></div>
                        <InnerSearchbar
                            navigate={navigate}
                            sourcePointListFromStore={sourcePointListFromStore}
                            destinationPointListFromStore={destinationPointListFromStore}
                            setSourceAndDestinationToStore={(args) =>
                                setSourceAndDestinationToStore(args)
                            }
                            selectedSearchFromRecent={selectedSearchFromRecent}
                            
                            setSelectedSearchFromRecent={(args) =>
                                setSelectedSearchFromRecent(args)
                            }
                            userDataFromStore={userDataFromStore}
                            filterDataFromStore={filterDataFromStore}
                            filterFromStore={filterFromStore}
                            heading="Book a ride with us to your favourite destination!"
                            />
                    </div> 
                    :
                    <div className="mweb404search">
                        {/* <div className="semicircle"></div> */}
                        <h2 className="mweb-head">Book a ride with us to your favourite destination!</h2>
                        <MobileTopBus
                                navigate={navigate}
                                isDesktopOrLaptop={isDesktopOrLaptop}
                                sourcePointListFromStore={sourcePointListFromStore}
                                destinationPointListFromStore={destinationPointListFromStore}
                                setSourceAndDestinationToStore={(args) =>
                                    setSourceAndDestinationToStore(args)
                                }
                               
                                selectedSearchFromRecent={selectedSearchFromRecent}
                               
                                setSelectedSearchFromRecent={(args) =>
                                    setSelectedSearchFromRecent(args)
                                }
                                viewCalendar={viewCalendar}
                                setViewCalendar={(args) => openCalendarForMobile(args)}
                                userDataFromStore={userDataFromStore}
                                filterDataFromStore={filterDataFromStore}
                                filterFromStore={filterFromStore}
                            />
                    </div>
                    }
            <CardGrid category={'Must-Visits'} categoryData={CategoryMustVisit}/>
            <NueGoScanner/>
        </div>

    );
}

const mapStateToProps = (state) => ({
    sourcePointFromStore: state.BookingReducer.sourcePoint,
    destinationPointFromStore: state.BookingReducer.destinationPoint,
    sourcePointListFromStore: state.BookingReducer.sourcePointList,
    destinationPointListFromStore: state.BookingReducer.destinationPointList,
    userDataFromStore: state.AuthReducer,
    filterDataFromStore: state.FilterReducer.filter,
    filterFromStore: state.FilterReducer.filter
});
const mapDispatchToProps = (dispatch) => ({
    setSourcePointToStore: (data) => dispatch(setSourcePoint(data)),
    setDestinationPointToStore: (data) => dispatch(setDestinationPoint(data)),
    setSourceAndDestinationToStore: (data) =>
        dispatch(setSourceAndDestination(data)),
});

export default connect(mapStateToProps, mapDispatchToProps) (Blog)