import "./styles.scss";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import TourImageOne from "../../../../../src/assets/images/tour-img-1.jpg";
import TourImageTwo from "../../../../../src/assets/images/tour-img-2.jpg";
import TourImageThree from "../../../../../src/assets/images/tour-img-3.jpg";
import TourImageFour from "../../../../../src/assets/images/tour-img-4.jpg";
import TourBtmImage from "../../../../../src/assets/images/tour-btm.png";
import RoadDsImage from "../../../../../src/assets/images/road-ds-img.png";
import RoadTopMbImg from "../../../../../src/assets/images/road-mb-top.png";
import RoadBtmMbImg from "../../../../../src/assets/images/road-mb-btm.png";
import BusDsImg from "../../../../../src/assets/images/ds-bus.png";
import RoadBtmDsImge from "../../../../../src/assets/images/road-btm-ds-img.png";
import VideoPlayerIcon from "../../../../../src/assets/images/video-player-icon.png";

const TourData = [
  {
    title: "“Starting our journey with our NueGo EV bus from peaks to coasts”",
    tourContent: [
      {
        image: TourImageOne,
        video: "",
        description:
          "NueGo Bus ready to Electrically pioneer through all of India",
        id: 1,
      },
      {
        image: TourImageTwo,
        video: "",
        description: "The NueGo team pledging safety and sustainability",
        id: 2,
      },
      {
        image: TourImageThree,
        video: "",
        description:
          "Naryal Breaking Ceremony facilitated by the CEO of Jammu Smart City - Dr Devansh Yadav ",
        id: 3,
      },
      {
        image: TourImageFour,
        video: "",
        description:
          "Flag Off to start our 4,000+ KMs journey with pride and joy",
        id: 4,
      },
    ],
  },
];

const TourSection = () => {
  const [isStickyActive, setIsStickyActive] = useState(false);
  const [playingVideoId, setPlayingVideoId] = useState(null);
  const [play, setPlay] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 992px)" });
  const isBelow992 = useMediaQuery({ query: "(max-width: 991px)" });
  const isBetween992And1300 = useMediaQuery({
    query: "(min-width: 992px) and (max-width: 1299px)",
  });
  const isAbove1300 = useMediaQuery({ query: "(min-width: 1300px)" });

  useEffect(() => {
    const handleScroll = () => {
      const tourSection = document.querySelector(".tour-container");
      const rect = tourSection.getBoundingClientRect();
      const isInView = rect.top <= 0 && rect.bottom >= window.innerHeight;

      setIsStickyActive(isInView);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const playHandler = (id) => {
    const videoElement = document.getElementById(id);

    if (playingVideoId !== id) {
      if (playingVideoId) {
        const previousVideoElement = document.getElementById(playingVideoId);
        if (previousVideoElement) {
          previousVideoElement.pause();
          setPlay(false);
        }
      }

      setPlayingVideoId(id);
      setPlay(true);
      videoElement.play();
    } else {
      if (play) {
        setPlay(false);
        videoElement.pause();
      } else {
        setPlay(true);
        videoElement.play();
      }
    }
  };

  const getVideoDimensions = () => {
    if (isBelow992) {
      return { width: 243, height: 200 }; 
    } else if (isBetween992And1300) {
      return { width: 200, height: 164 }; 
    } else if (isAbove1300) {
      return { width: 243, height: 200 }; 
    }
    return { width: 243, height: 200 }; // Default dimensions
  };

  const videoDimensions = getVideoDimensions();

  return (
    <div className={`tour-container ${isStickyActive ? "sticky-active" : ""}`}>
      {isDesktopOrLaptop && (
        <div className="km-part">
          <p className="km-cover">Kms Covered</p>
          <p className="km">
            <span>0+</span>
            <span>0%</span>
          </p>
        </div>
      )}
      <div className="tour-part">
        <p className="title">Kickstarting the ultimate India Tour</p>
        {!isDesktopOrLaptop && (
          <div className="km-part">
            <p className="km-cover">Kms Covered</p>
            <p className="km">
              <span>0+</span>
              <span>0%</span>
            </p>
          </div>
        )}

        <div className="bus-sticky">
          <img src={BusDsImg} alt="bus" className="bus-img" />
        </div>

        <div>
          {TourData.map((data, index) => {
            return (
              <div className="sub-container" key={index}>
                <p
                  className={
                    index % 2 === 0
                      ? "sub-container-title"
                      : "sub-container-right-title"
                  }
                >
                  {data.title}
                </p>
                <div className="tour-img-section">
                  {data.tourContent.map((data, subIndex) => {
                    return (
                      <div
                        key={subIndex}
                        className={
                          subIndex === 1
                            ? "right-space video-player-part"
                            : "video-player-part"
                        }
                        style={{ cursor: data.video !== "" ? "pointer" : "" }}
                      >
                        <video
                          onClick={() => {
                            if (data.video) {
                              playHandler(data.id);
                            }
                          }}
                          poster={data.image}
                          preload="none"
                          width={videoDimensions?.width}
                          height={videoDimensions?.height}
                          id={data.id}
                          className={"video-tag"}
                        >
                          <source src={data.video} type="video/mp4" />
                        </video>
                        {data.video !== "" &&
                          (!play || playingVideoId !== data.id) && (
                            <div>
                              <img
                                onClick={() => playHandler(data.id)}
                                alt="player-icon"
                                src={VideoPlayerIcon}
                                className="player-icon"
                                height={40}
                                width={40}
                              />
                            </div>
                          )}
                        <p className="mb-0 img-below-text">
                          {data.description}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        <div className="road-img-part">
          {isDesktopOrLaptop && (
            <img src={RoadTopMbImg} alt="road-img" className="road-top-img" />
          )}
          <p className="number">01 </p>
          <p className="location-name-first"> Kashmir</p>
          {/* <img src={RoadDsImage} alt="road-img" className="road-ds-img" />
          <p className="number">02 </p>
          <p className="location-name">Amritsar</p>
          <img src={RoadDsImage} alt="road-img" className="road-ds-img" />
          <p className="number">03 </p>
          <p className="location-name">Delhi</p>
          <img src={RoadDsImage} alt="road-img" className={"road-ds-img"} />
          <p className="number">04 </p>
          <p className="location-name">Jaipur</p> */}
          {!isDesktopOrLaptop && (
            <img
              src={isDesktopOrLaptop ? RoadBtmDsImge : RoadBtmMbImg}
              alt="road-img"
              className={isDesktopOrLaptop ? "road-ds-diff-img" : "tour-mb-btm"}
            />
          )}
        </div>

        <div>
          <p className="reaching">Were Reaching Soon</p>
          <p className="location">To our next stop Amritsar!</p>
          <div className="tour-place-img">
            <img
              src={TourBtmImage}
              alt="tour-btm-img"
              className={"tour-btm-img"}
            />
            <p>Amritsar</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TourSection;
